.App {
  display: flex;
  text-align: center;
  min-height: 100vh;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-direction: column;
  font-family: sans-serif;
  /*flex-wrap: wrap;*/
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.button {
  border: none;
  color: white;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 10px;
}

.add-button {
  background-color: white;
  color: black;
  border: 2px solid #4CAF50;
}

.add-button:hover {
  background-color: #4CAF50;
  color: white;
}

.delete-button {
  background-color: white;
  color: black;
  border: 2px solid #dc3545;
  margin-top: auto;
  width: 70%;
}

.delete-button:hover {
  background-color: #dc3545;
  color: white;
}


.user-list{
  max-width: 1400px;
  display: flex;
  flex-wrap: wrap;
}

.post-list {
  display: flex;
  max-width: 1300px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
}

.user-item, .post-item {
  display: flex;
  flex-flow: column;
  align-items: center;
  border-style: solid;
  margin: 1rem;
  align-self: center;
  padding: 1rem;
  max-width: 360px;
  min-height: 440px;
}

/*Switch Between Users & Posts*/
.container {
  display: flex;
}

.color-picker {
  display: flex;
  flex-direction: row;
  align-items: center;
}

input[type="color"] {
  margin: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 15px;
  cursor: pointer;
}
input[type="color"]::-webkit-color-swatch {
  border-radius: 15px;
  border: none;
}
input[type="color"]::-moz-color-swatch {
  border-radius: 15px;
  border: none;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input[type=checkbox] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

.switch input[type=checkbox]:checked + .slider {
  background-color: #2196F3;
}

.switch input[type=checkbox]:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.switch input[type=checkbox]:checked + .slider:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.about-page {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

#page-404-body {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  margin: 0;
  background: #131313;
  color: #fff;
  font-size: 96px;
  font-family: 'Fira Mono', monospace;
  letter-spacing: -7px;
}

#page-404 {
  animation: glitch 1s linear infinite;
}

@keyframes glitch{
  2%,64%{
    transform: translate(2px,0) skew(0deg);
  }
  4%,60%{
    transform: translate(-2px,0) skew(0deg);
  }
  62%{
    transform: translate(0,0) skew(5deg);
  }
}

#page-404:before,
#page-404:after{
  content: attr(title);
  position: absolute;
  left: 0;
}

#page-404:before{
  animation: glitchTop 1s linear infinite;
  clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
}

@keyframes glitchTop{
  2%,64%{
    transform: translate(2px,-2px);
  }
  4%,60%{
    transform: translate(-2px,2px);
  }
  62%{
    transform: translate(13px,-1px) skew(-13deg);
  }
}

#page-404:after{
  animation: glitchBotom 1.5s linear infinite;
  clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
  -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
}

@keyframes glitchBotom{
  2%,64%{
    transform: translate(-2px,0);
  }
  4%,60%{
    transform: translate(-2px,0);
  }
  62%{
    transform: translate(-22px,5px) skew(21deg);
  }
}

footer {
  background-color: white;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 80px;
  display: flex;
  align-items: center;
  box-shadow: 0 0 25px 0 black;
  justify-content: center;
}

footer * {
  display: inline;
}

footer li {
  margin: 20px;
}

footer li a {
  color: black;
  text-decoration: none;
}
