.user-add-form {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: rgba(0,0,0,.5);
    box-shadow: 0 15px 25px rgba(0,0,0,.6);
    border-radius: 20px;
    box-sizing: border-box;
    padding: 20px;
    width: 320px;
}

.user-add-form > h2 {
    color: white;
    font-family: sans-serif;
}

.user-add-form div {
    display: flex;
    align-items: center;
}

.user-add-form div label{
    width: 50px;
}

.user-add-form div label[for="gold-client"] {
    width: 90px;
}

input[type="text"],  input[type="email"]{
    font-size: 16px;
    margin: 10px;
    border: none;
    border-bottom: 1px solid #fff;
    outline: none;
    background: transparent;
    color: #fff;
}

input[type="checkbox"] {
    background-color: #fff;
    margin: 1rem;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 0.15em;
}

input[type="checkbox"]::before {
    background-color: #03e9f4;
}

.user-add-form input:focus ~ label {
    color: #03e9f4;
}

/* This is our style for the invalid fields */
.error {
    text-align: left;
    color: red;
    margin: 0.5rem 0 1rem 0;
}

input.invalid {
    border-color: #900;
}

input:focus.invalid {
    outline: none;
}


